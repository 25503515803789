import dynamic from 'next/dynamic';
import styles from './styles.module.scss';
import HomeMainBanner from './MainBanner';
import MostPaidCarousel from './Carousels/MostPaid';
import HomeStories from '../Stories';
import BigestWinsCarousel from './Carousels/BiggestWins';
import Layout from '@/components/core/Layout';
import GamesSectionCarousel from './Carousels/Games';
import GameSearchInput from '../V2/GameSearch/Input';

import ProvidersSectionSkeleton from '../V2/ProvidersSection/Providers';
const ProvidersSection = dynamic(() => import('../V2/ProvidersSection'), {
  ssr: false,
  loading: () => (
    <ProvidersSectionSkeleton
      data={[]}
      isLoading
      error={null}
      refetch={() => undefined}
    />
  ),
});
import RecentlyPlayedCarousel from './Carousels/RecentlyPlayed';

const LatestBets = dynamic(() => import('../LatestBets'), { ssr: false });
const LuckiestWinsCardVersion = dynamic(
  () => import('./Carousels/LuckiestWins/Card'),
  { ssr: false },
);

function HomeComponent() {
  return (
    <Layout>
      <section className={styles.container}>
        <div className={styles.container__mobile__only}>
          <HomeStories />
        </div>
        <div className={styles.container__desktop__only}>
          <HomeMainBanner />
        </div>
        <GameSearchInput />
        <RecentlyPlayedCarousel />
        <BigestWinsCarousel />
        <MostPaidCarousel />
        <GamesSectionCarousel />
        <LuckiestWinsCardVersion />
        <ProvidersSection />
        <LatestBets />
      </section>
    </Layout>
  );
}

export default HomeComponent;
