import { RankQueryQueys } from '../../queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getRankStatusByUser } from './service';
import { AxiosError } from 'axios';
import { GenericError } from '@/models/generic-error';
import { UseRankStatusByUserProps } from './props';
import { AuthService } from '@/services/AuthService';
import { RankStatus } from '@/models/rank/status';

export function useRankStatusByUser(options?: UseRankStatusByUserProps) {
  const { enabled = true, refetchOnMount = true } = options || {};
  const { data, error, refetch, isPending, fetchStatus, isFetching } = useQuery<
    RankStatus,
    AxiosError<GenericError>
  >({
    queryKey: [RankQueryQueys.ByUser],
    queryFn: getRankStatusByUser,
    enabled: enabled && AuthService.isAuthorized(),
    staleTime: 5 * 1000,
    refetchOnMount,
  });

  return {
    rankStatus: data,
    error,
    refetch,
    isLoading: isPending && fetchStatus !== 'idle',
    isFetching,
  };
}
